
/*
 1: General-------------------------------------------------------------------
*/
.cancelButtonText {
  font: normal normal normal 17px/28px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}

.purchaseButtonText {
  text-align: center;
  font: normal normal bold 22px/26px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.purchaseButton {
  background: transparent linear-gradient(180deg, $main-color 0%, $base-color 100%) 0%
  0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}

.scrollableDiv {
  height: 70vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-sizing: border-box;
}
.scrollableDiv::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.alertmessage{
  margin-top: -18px;
  margin-bottom: 20px;
  font-size: 16px;
}
/*
  Notification-------------------------------------------------------------------
*/
.notification {
  display: flex;
  padding: 5px;
  align-items: center;
}

.isUnread {
  background: #edf8f4;
}

.notificationTost {
  top: 87px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}
.notificationCount .MuiBadge-anchorOriginTopRightRectangle{
  top: -8px;
  right: 3px;
}

.notificationTostAlert {
  background: #5eb9ff;
  font-weight: 800;
}

.notificationbutton {
  height: 35px;
  border-radius: 20px;
  color: green;
  width: 100px;
}

.notificatinTime {
  font-size: 14px;
  margin-right: 9px;
  display: flex;
  align-items: center;
  color: gray;
  min-width: 5%;
  font-family: sans-serif;
}

.notificationBtnDiv {
  display: flex;
  margin-left: 20px;
}

.notificationDetail {
  min-width: 61%;
}

/*
 1.1: Typography-------------------------------------------------------------------
*/
.err {
  color: #e63946;
}
.errBackground {
  background-color: #e63946;
}
.buttonBorderGreen {
  border-color: #02c39a;
}
.linkText {
  text-decoration: underline;
  color: #0000ff;
}
.helperText {
  font: normal normal normal 14px/16px Helvetica;
  color: #8a8e91;
}
.helperTextLarge {
  font: normal normal normal 20px Helvetica;
  color: #8a8e91;
}
.subHeader {
  font: normal normal normal 13px/15px Helvetica;
  color: #8a8e91;
}
.highlightText {
  font: normal normal bold 15px/22px Helvetica;
  color: $main-color;
}
.highlightHeader {
  font: normal normal bold 30px Helvetica;
  color: $main-color;
}
.finePrint {
  font: normal normal normal 10px/12px Helvetica;
  color: #8a8e91;
}
.underline {
  text-decoration: underline;
}
.noUnderline {
  text-decoration: none;
}
.capitalize{
  text-transform: capitalize
}
/*
 1.2: Defaults-------------------------------------------------------------------
*/

.primaryColor {
  color: #02c39a;
}
.colorOrange {
  color: #f77f00;
}
.colorBlack {
  color: black;
}
.primaryColorBackground {
  background-color: #02c39a;
}
.orangeColorBackground {
  background-color: #f77f00;
}
.testColorBackground {
  background-color: #c35f02;
}
.filler {
  flex-grow: 1;
}
.form {
  display: flex;
  flex-direction: column;
}
.column {
  display: flex;
  flex-direction: column;
}
.card {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 25px;
  box-sizing: border-box;
}
.posAbsolute {
  position: absolute;
}
.posRelative {
  position: relative;
}
.heightPlaceholder {
  min-height: 30px;
}
.heightPlaceholder40 {
  min-height: 40px;
}
.gridCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.columnMaxWidth {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.columnAlignCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.selectedbutton{
  background: #058360;
  color: white;
  font-weight: 600;
  min-width: 50px;
}
.selectedbutton :hover{
  color: black;
}
.columnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.rowStretch {
  display: flex;
  align-items: stretch;
}
.rowCenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.justify-between{
  justify-content: space-between;
}
.rowCenterMinWidth {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.infoCard {
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
}
.infoCardLeft {
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
}
.infoPaper {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
}
.checkboxWrapperVertical {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 30px;
}
.checkboxWrapperHorizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-left: 30px;
}

.flex {
  display: flex;
}
.flexWrap {
  flex-wrap: wrap
}
.flexCenter {
  display: flex;
  align-items: center;
}
.alignEnd {
  text-align: end;
}
.maxWidth {
  width: 100%;
}
.minWidth75{
  min-width: 75px;
}
.maxHeight {
  height: 100%;
}
.max-height {
  max-height: 100%;
}
.marginAuto{
  margin: auto;
}
.margin5Bottom {
  margin-bottom: 5px;
}
.margin10 {
  margin: 10px;
}
.margin20LessTop {
  margin-top: -20px;
}
.margin10LessTop {
  margin-top: -10px;
}
.margin30LessTop {
  margin-top: -30px;
}
.margin10Left {
  margin-left: 10px;
}
.margin10Right {
  margin-right: 10px;
}
.margin10Bottom {
  margin-bottom: 10px;
}
.margin10Top {
  margin-top: 10px;
}
.margin30Top {
  margin-top: 30px;
}
.margin35Top{
  margin-top: 35%;
}
.margin50Top {
  margin-top: 50px;
}
.margin10Vertical {
  margin-top: 10px;
  margin-bottom: 10px;
}
.margin20Horizontal {
  margin-left: 20px;
  margin-right: 20px;
}
.margin20Right {
  margin-right: 20px;
}
.margin20Top {
  margin-top: 20px;
}
.margin20Bottom {
  margin-bottom: 20px;
}
.margin20Vertical {
  margin-top: 20px;
  margin-bottom: 20px;
}
.radius10{
  border-radius: 10px;
}
.alignFlexStart{
  align-items: flex-start !important
}
.padding0 {
  padding: 0px !important;
}
.padding10 {
  padding: 10px;
}
.padding10Horizontal {
  padding-left: 10px;
  padding-right: 10px;
}
.paddingTopBox {
  padding: 40px 10px 10px 10px;
}
.ponter{
  cursor: pointer;
  padding-right: 5px;
}
.copyPlayerinfoDiv{
  text-align: right;
  padding-bottom: 13px;
}
.directorLeagueCopyToClip{
  display: flex;
  align-items: center;
  justify-content: center;
}
.directorLeagueCopyToClipBlock{
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.padding20 {
  padding: 20px;
}
.padding20Right {
  padding-right: 20px;
}
.padding20Vertical {
  padding-top: 20px;
  padding-bottom: 20px;
}
.margin30 {
  margin: 30px;
}
.fieldMarginTop {
  margin-top: 10px;
  width: 100%;
}
.fieldHalfMarginTop {
  margin-top: 10px;
  width: 50%;
}
.field45MarginTop {
  margin-top: 10px;
  width: 45%;
}
.highlightButton {
  border-color: #02c39a;
  margin-top: 10px;
  width: 100%;
}
.widthHalf {
  width: 50%;
}
.heightHalf {
  height: 50%;
}
.height142{
  height: 142px !important;
}
.widthQuarter {
  width: 25%;
}
.widthThird {
  width: 33%;
}
.widthFifth {
  width: 20%;
}
.width80 {
  width: 80%;
}
.width55 {
  width: 55%;
}
.width30 {
  width: 30%;
}
.width10 {
  width: 10%;
}
.width18 {
  width: 18%;
}
.width22Half5 {
  width: 22.5%;
}
.width75 {
  width: 75%;
}
.responsiveImage30 {
  width: 30%;
  padding-bottom: 25%;
}
.width150 {
  width: 150px;
}
.image30 {
  width: 30px;
  height: 30px;
}
.image50 {
  width: 50px;
  height: 50px;
}
.image100 {
  width: 100px;
  height: 100px;
}
.image150 {
  width: 150px;
  height: 150px;
}
.image200 {
  width: 200px;
  height: 200px;
}
.image150Rounded {
  width: 150px;
  height: 150px;
  border-radius: 25px;
}
.image200Rounded {
  width: 200px;
  height: 200px;
  border-radius: 25px;
}
.image250Rounded {
  width: 250px;
  height: 250px;
  border-radius: 25px;
  overflow: hidden;
}
.image250 {
  width: 250px;
  height: 250px;
}
.image300 {
  width: 300px;
  height: 300px;
}
.image400 {
  width: 400px;
  height: 400px;
}
.fontSize10 {
  font-size: 10px;
}
.fontSize20 {
  font-size: 20px !important;
}
.fontSize40 {
  font-size: 40px;
}
.borderTop5px{
  border-top: 5px solid #888888;
}
.areaCenter{
  text-align: center
}
.fontWeight700{
  font-weight: 700 !important;
}

/*
 2: Pages-------------------------------------------------------------------
*/

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 100%;
}
.pageGridWrapper {
  width: 100%;
  padding: 10px;
}
.pageWrapperRow {
  display: flex;
  padding: 20px;
}

/*
 2.1: Landing-------------------------------------------------------------------
*/
.page {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
}
.switchPopup {
  display: flex;
  flex-direction: row;
  min-height: 80vh;
}

.pageLeft {
  background: $base-color 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
}
.SwitchPopupPageLeft{
  background: $pickle-light-color 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  border-right: 2px solid $pickle-base-color;
}
.pageRight {
  background: #edf8f4 0% 0% no-repeat padding-box;
}
.switchableColorLight{
  background: $pickle-light-color 0% 0% no-repeat padding-box;
}
.landingPageQuoteWrapper {
  width: 70%;
}
.loginContainer {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 20%;
  @media (max-width: 1620px) {
    width: 60%;
    padding-left: 10%;
  }
}
@media (max-width: 900px) {
  .landingPageQuoteWrapper {
    width: 70%;
    margin-top: 100px;
  }
  .loginContainer {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 20%;
  }
}
.landingPageQuote {
  text-align: left;
  font: italic normal bold 22px/26px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
}
.landingPageAuthor {
  text-align: left;
  font: normal normal normal 22px/26px Helvetica;
  letter-spacing: 0px;
  color: #c8d952;
}
.landingPagePlayerIconWrapper {
  position: absolute;
  bottom: 20%;
  right: -40%;
}
.landingPageTextFieldIcon {
  text-align: left;
  font: normal normal normal 20px/30px Material Design Icons;
  letter-spacing: 0px;
  color: #d0d0d0;
  opacity: 1;
}
.landingPageLogoWrapper {
  margin: 20px;
  position: absolute;
  top: 0px;
}
.landingPageNewMemberText {
  text-align: left;
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.landingPageSignupTextSmall {
  text-align: left;
  font: normal normal bold 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.landingPageLoginText {
  text-align: left;
  font: normal normal 900 44px/53px Helvetica;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
  margin-bottom: 60px;
}
.switchPopupLoginText {
  text-align: left;
  font: normal normal 900 44px/53px Helvetica;
  letter-spacing: 0px;
  color: $pickle-base-color;
  opacity: 1;
}
.landingPagePrimarySmallText {
  text-align: left;
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
}
.switchPopupPrimarySmallText{
  text-align: left;
    font: normal normal normal 16px/19px Helvetica;
    letter-spacing: 0px;
    color: $pickle-base-color;
    opacity: 1;
}
.switchPopupwelcomeText{
    width: 249px;
    /* UI Properties */

    text-align: left;
    font: normal normal bold 36px/43px SF Pro Display;
    letter-spacing: 0px;
    color: #340761;
    opacity: 1;
  &.margin30Left {
    margin-left: 30px;
  }
}
.landingPageSecondarySmallText {
  text-align: left;
  font: normal normal normal 16px/30px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
}

.loginItem {
  margin-bottom: 20px;
  width: 100%;
  align-self: center;
}
.loginButton {
  background: $main-color 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00d78b4d;
  border-radius: 10px;
  opacity: 1;
}
.switchPopUpButton{
  background: $pickle-main-color 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $pickle-main-color;
    border-radius: 10px;
    color: white;
    font-weight: 600;
    opacity: 1;
}
.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;

  padding: 12px 10px 12px 36px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

}
.login-with-facebook-btn {
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: white;
   svg {
    margin-right: 8px;
  }
}

.login-with-google-btn:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}
.logindiv{
  display: flex;
  justify-content:space-between;
}
/*
 2.1.1: SignUp-------------------------------------------------------------------
*/
.pageSignUp {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #edf8f4 0% 0% no-repeat padding-box;
  width: 100%;
  align-items: center;
}
.signUpInnerDiv {
  display: flex;
  flex-direction: column;
  width: 28vw;
  margin-top: 20px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.signUpResponsiveField {
  margin-top: -30px;
}
.linkbutton {
  border: none;
  text-decoration: underline;
  color: blue;
  font-size: small;
}
.signUpText {
  margin-top: 95px;
  font: normal normal 900 22px/26px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.signUpRatingText {
  margin-top: -20px;
  text-align: center;
  font: normal normal bold 15px/30px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
@media (max-width: 900px) {
  .signUpInnerDiv {
    display: flex;
    flex-direction: column;
    width: 75vw;
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px;
  }
  .signUpResponsiveField {
    margin-top: 0px;
  }
}
.signUpSubtitleText {
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
}
.signUpNextButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid $main-color;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
}
.signUpNextButtonText {
  text-align: center;
  font: normal normal bold 15px/30px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.signUpErrorText {
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #f70909;
}
.signUpIcon {
  color: $main-color;
  width: 20px;
  height: 20px;
}
.signUpRatingWrapper {
  position: absolute;
  top: -10px;
  right: 0px;
}
.signUpRatingDescriptionWrapper {
  max-width: 500px;
  padding: 10px;
}
.signUpIconButton {
  width: 25px;
  height: 25px;
}
/*
 2.2: AppRouter-------------------------------------------------------------------
*/

.appBar {
  position: fixed;
  padding: 20px;
  padding-left: 37px;
  height: 75px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
}
.appBarGSMWrapper {
  margin-left: 50px;
}
.dashboardSideNavButtonWrapper {
  padding-top: 20px;
  padding-bottom: 20px;
  width: "100%";
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.dashboardSideNavButtonText {
  margin-top: 10px;
  font: normal normal bold 14px/16px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
@media (max-width: 900px) {
  .appBar {
    position: fixed;
    padding: 20px;
    padding-left: 10px;
    height: 60px;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
  }
  .dashboardSideNavButtonWrapper {
    padding-top: 20px;
    padding-bottom: 20px;
    width: "100%";
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }
  .dashboardSideNavButtonText {
    margin-top: 0px;
    margin-left: 20px;
    font: normal normal bold 14px/16px Helvetica;
    letter-spacing: 0px;
    color: $main-color;
    opacity: 1;
  }
}
.appBaGSMWrapper{
  margin-left: 1.5%;
}
.appBarGSMText {
  text-align: left;
  font: normal normal 900 22px/26px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.appBarLocationText {
  margin-left: 5px;
  color: $main-color;
  text-align: left;
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
}
.appBarLocationIcon {
  color: $main-color;
  width: 20px;
  height: 20px;
}
.appBarProfileButtonText {
  margin-right: 15px;
  text-align: left;
  font: normal normal medium 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
}
.appBarProfileMenu {
  border: 1px solid var(--unnamed-color-e9eef2);
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9eef2;
  border-radius: 15px;
}
.appBarProfileMenuText {
  margin-left: 10px;
  text-align: left;
  font: normal normal medium 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
}
.appBarProfileMenuIcon {
  text-align: left;
  font: normal normal normal 24px/29px Material Design Icons;
  letter-spacing: 0px;
  color: #c8d952;
  opacity: 1;
}
.appBarUploadScoreButtonText {
  margin-left: 15px;
  text-align: left;
  font: normal normal medium 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
}
.appBarUploadScoreButtonIcon {
  color: $base-color;
}
.dashboardWrapper {
  display: flex;
  min-height: 100vh;
  box-sizing: content-box;
}

.dashboardPage {
  flex-grow: 1;
  margin-top: 75px;
  padding: 20px;
}
.dashboardBackButton {
  margin-top: -30px;
  margin-left: -20px;
  margin-bottom: 5px;
}
.dashboardViewAll{
  color: #0051ff;
  font-size: 18px;
}

.loadingIcon {
  width: 30%;
  height: auto;
}

/*
 2.3: Profile-------------------------------------------------------------------
*/
.preferencesWrapper {
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-sizing: border-box;
  height: 100%;
  max-width: 78vw;
}
.preferencesWrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.profileContentWrapper {
  min-height: 80vh;
}

.scrollableDiv {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-sizing: border-box;
}
.profileInfoCardTitle {
  text-align: left;
  font: normal normal bold 20px/24px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.profileInfoCardSubtitle {
  color: #00243e;
}
.profileNavButton {
  border-top: 1px solid #e9eef2;
  border-bottom: 1px solid #e9eef2;
  opacity: 1;
}
.profileNavButtonTextUnselected {
  text-align: left;
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.profileNavButtonTextSelected {
  text-align: left;
  font: normal normal bold 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.profileBoldFieldText {
  text-align: left;
  font: normal normal bold 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.profileRequiredFieldText {
  text-align: left;
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.profileOptionalFieldText {
  text-align: left;
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
  opacity: 1;
}
.profileHighlightFieldText {
  text-align: center;
  font: normal normal normal 16px/22px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.profileSaveButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid $main-color;
  border-radius: 10px;
  opacity: 1;
  min-width: 100px;
}
.CancelButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #f70909;
  border-radius: 10px;
  opacity: 1;
  min-width: 100px;
}
.profileSaveButtonPlaceholder {
  min-height: 45px;
}
.profileSaveButtonText {
  text-align: center;
  font: normal normal bold 15px/30px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
  white-space: nowrap;
}
.profileUpdateButtonText {
  text-align: center;
  font: normal normal bold 15px/30px Helvetica;
  letter-spacing: 0px;
  color: #f70909;
  opacity: 1;
}
.profileSubpageTitle {
  font: normal normal bold 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
/*
 2.3.1: Information-------------------------------------------------------------------
*/
.profilePhotoWrapper {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: #c0c0c0;
  padding: 10px;
}
.profilePhoto {
  width: 200px;
  height: 200px;
}
.profileRatingsWrapper {
  margin: 10px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}
/*
 2.3.2: Preferences-------------------------------------------------------------------
*/

.profileCheckboxWrapper {
  padding: 5px;
}

/*
 2.3.3: ViewPlayer-------------------------------------------------------------------
*/
.rowCenter50 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50%;
}
.elementIndent {
  margin-left: 10px;
}
.viewPlayerContentWrapper {
  height: 70vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-sizing: border-box;
  max-width: 78vw;
}
.viewPlayerContentWrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.viewPlayerInfoLabel {
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
  opacity: 1;
}
.viewPlayerInfo {
  text-align: left;
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
  word-wrap: break-word;
}
.viewPlayerAboutText {
  text-align: left;
  font: normal normal normal 16px/23px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
/*
 2.3.4: Rewards-------------------------------------------------------------------
*/
.profilePointsRewardGrid {
  height: 100%;
  padding: 10px;
}
.rewardsPointsCard {
  border-radius: 10px;
  background-color: #ffffff;
  height: 200px;
}
.rewardsPointsCard {
  border-radius: 10px;
  background-color: #ffffff;
  height: 200px;
}
.rewardsEarnPointsWrapper {
  border-radius: 10px;
  background-color: #ffffff;
  min-height: 200px;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}
.rewardsEarnMorePointsCard {
  height: 160px;
  background: #f9fbe5 0% 0% no-repeat padding-box;
  border: 1px dashed #00243e;
  border-radius: 15px;
  opacity: 1;
  justify-content: space-evenly;
}
.rewardsEarnPointsLinkWrapper {
  text-decoration: none;
  width: 30%;
}
.rewardsPointsTotalWrapper {
  background: transparent linear-gradient(180deg, $main-color 0%, $base-color 100%) 0%
  0% no-repeat padding-box;
  border-radius: 10px;
  width: 100%;
  height: 60%;
  opacity: 1;
  justify-content: center;
}
.rewardsPointsHeader {
  text-align: left;
  font: normal normal bold 20px/24px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.rewardsPointsTotalText {
  text-align: center;
  font: normal normal bold 30px/36px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.rewardsPointsTotalSubheaderText {
  font: normal normal normal 14px/16px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.rewardsPointsInfoText {
  font: normal normal normal 15px/30px Helvetica;
  letter-spacing: 0px;
  color: #0c2c1f;
}
.rewardsPointsInfoLink {
  text-decoration: underline;
  font: normal normal normal 15px/30px Helvetica;
  letter-spacing: 0px;
  color: #0000ff;
}
.rewardsBoldCardText {
  font: normal normal bold 14px/16px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.rewardsMorePointsDescription {
  text-align: center;
  font: normal normal normal 14px/16px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
  opacity: 1;
}
.rewardsHighlightText {
  font: normal normal bold 14px/16px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.rewardsMethodIcon {
  text-align: center;
  font: normal normal normal 32px/38px Material Design Icons;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.rewardsItemPointsText {
  font: normal normal bold 15px/22px Helvetica;
  letter-spacing: 0px;
  color: #f4b425;
  opacity: 1;
}
.rewardsCardItem {
  border-radius: 10px;
}
/*
 2.3.5: Statistics-------------------------------------------------------------------
*/
.circularProgressInnerElement {
  top: 70px;
}
.circularProgressText {
  text-align: left;
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.circularProgressPercent {
  text-align: left;
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.circularProgressBottom {
  z-index: 1;
}
.circularProgressTop {
  z-index: 2;
}

.profileStatisticsHeaderText {
  text-align: center;
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
}
.profileStatisticsHeaderValueText {
  text-align: center;
  font: normal normal bold 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $base-color;
}
.profileStatisticsLeagueTitle {
  text-align: left;
  font: normal normal medium 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.profileStatisticsLeagueElement {
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 20px;
}
.circularProgressWrapper {
  height: 180px;
}
/*
 2.4: Home-------------------------------------------------------------------
*/
.leagueDirectorInfo {
  display: flex;
  flex-direction: column;
  margin: 10px;
  height: 150px;
  justify-content: space-around;
}

.filterCard {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
}

.filterCardSubtitle {
  margin-left: 20px;
  margin-top: 20px;
}
.filterCardCheckBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 10px;
}
.leagueCardTitle {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 10px;
}
.customTabs {
  margin-top: -20px;
  margin-bottom: 20px;
}
.tabsWrapper {
  width: 100%;
  display: flex;
  align-items: center;
}
.tabsWidthWrapper {
  max-width: 80%;
}
@media (max-width: 900px) {
  .tabsWrapper {
    width: 75vw;
    display: flex;
    align-items: center;
  }
}
/*
 2.4.1: JoinLeague-------------------------------------------------------------------
*/
.priceSlider {
  width: 80%;
}
.leaguesPageLoadMoreText {
  text-align: center;
  font: normal normal normal 15px/22px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
/*
 2.4.2: LeagueView-------------------------------------------------------------------
*/
.leaguePurchaseInfoPaper {
  width: 50%;
  height: 100%;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.leaguePurchaseDiscountText {
  text-align: right;
  font: normal normal normal 13px/22px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
}
.leaguePageHeaderWrapperMainSection {
  display: flex;
  justify-content: space-between;
}

.leaguePageHeaderWrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}
.leaguePurchaseDirectorInfo {
  flex-grow: 1;
  height: 150px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.couponWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.leaguePurchaseTermsWrapper {
  flex-direction: row;
  display: flex;
  margin-left: -10px;
  align-items: center;
}
.viewLeagueInfoTabs {
  margin-top: -20px;
  margin-bottom: 20px;
}
.leagueMainContentWrapper {
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-sizing: border-box;
  max-height: 100%;
  max-width: 78vw;
}
.leagueMainContentWrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.leagueHeaderImage {
  opacity: 1;
  padding-top: 50px;
}
.leagueHeaderTextWrapper {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
}
.viewLeagueTitleText {
  text-align: left;
  font: normal normal bold 30px/36px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 10px;
}
@media (max-width: 900px) {
  .viewLeagueTitleText {
    text-align: left;
    font: normal normal bold 18px/24px Helvetica;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 10px;
  }
}
.viewLeagueSubtitleText {
  margin-bottom: 20px;
  text-align: left;
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.viewLeagueSubtitleIcon {
  margin-bottom: 20px;
  color: #ffffff;
  width: 20px;
  height: 20px;
}
.viewLeagueCardHeader {
  text-align: left;
  font: normal normal bold 20px/24px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.viewLeagueCardDivider {
  background-color: #c8d952;
}
.viewLeagueRegDateHeader {
  text-align: left;
  font: normal normal normal 16px/22px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
}
.viewLeagueRegDeadlineText {
  text-align: left;
  font: normal normal normal 15px/22px Helvetica;
  letter-spacing: 0px;
  color: #f70909;
  opacity: 1;
}
.viewLeagueRegDateText {
  text-align: left;
  font: normal normal medium 16px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
}
.viewLeagueSubCategoryText {
  text-align: left;
  font: normal normal bold 16px/26px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
}
.viewLeagueDescriptionText {
  text-align: left;
  font: normal normal medium 16px/26px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.viewLeagueSignMessageBlock {
  text-align: center;
  margin-top: 50px;
  .viewLeagueDescriptionText {
    text-align: center;
  }
  .referralSendButton {
    color: #fff;
    margin-top: 10px;
    &:hover {
      color: #000;
    }
  }
}
.viewLeagueCategoryText {
  text-align: left;
  font: normal normal bold 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
  padding-top: 20px;
}
.viewLeaguePriceBackground {
  position: absolute;
  right: 20px;
  top: -10px;
}
.viewLeaguePriceText {
  font: normal normal bold 18px/16px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
}
.viewLeaguePriceTextWrapper {
  position: absolute;
  z-index: 1;
  right: 50px;
  top: 12px;
}
.viewLeagueCheckoutButton {
  background: #f4b425 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 32px;
  opacity: 1;
  margin-top: 10px;
  height: 50px;
}
.viewLeagueLeaveButton {
  background: #fff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 32px;
  opacity: 1;
  margin-top: 10px;
  height: 50px;
}
.viewLeagueCheckoutButtonText {
  text-align: center;
  font: normal normal bold 22px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.viewLeagueDirectorContactButton {
  margin: 10px;
  border: 1px solid var(--unnamed-color-c8d952);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c8d952;
  opacity: 1;
}
.viewLeagueDirectorContactIcon {
  text-align: left;
  font: normal normal normal 24px/29px Material Design Icons;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
  cursor: pointer;
  &.Leaguepdf{
    color:#ffffff !important;
  }
}

.LeagueInfoCopyButton{
  margin: 10px !important;
  border: 1px solid var(--unnamed-color-c8d952) !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #c8d952 !important;
  opacity: 1 !important;
  &.facebook-share-btn {
    padding: 12px !important;
    border-radius: 50px;
    display: flex;
    &.gen-pdf-btn{
      background-color: #d24644 !important;
      border: 1px solid #d24644 !important
    }
  }
}
.facebook-activity-share-btn {
  padding: 6px 12px !important;
  display: flex;
  align-items: center;
  border: 1px solid #1f56fb !important;
  background-color: #1f56fb !important;
  color: #FFFFFF !important;
  border-radius: 5px;
  margin-left: 10px;
  svg {
    fill: #fff;
    margin-right: 5px;
  }
}
.withdrawTextOncard{
  bottom: 120px;
  left:26px;
  color: #D23;
  position: absolute;
  border: 0.5rem solid #D23;
  -webkit-mask-position: 2rem 3rem;
  font-size: 2rem;
  transform: rotate(35deg);
  font-weight: 700;
  display: inline-block;
  padding: 0.25rem 1rem;
  text-transform: uppercase;
  border-radius: 1rem;
  font-family: 'Courier';
  -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
  -webkit-mask-size: 944px 604px;
  mix-blend-mode: multiply;
}

.viewLeagueRewardIcon {
  text-align: left;
  font: normal normal normal 24px/29px Material Design Icons;
  letter-spacing: 0px;
  color: #c8d952;
  opacity: 1;
}
.viewLeagueRewardText {
  text-align: left;
  font: normal normal normal 20px/44px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.viewLeagueRewardValueText {
  text-align: left;
  font: normal normal 600 20px/44px Helvetica;
  letter-spacing: 0.5px;
  color: $base-color;
  opacity: 1;
}
.viewLeagueProfileImageTextWrapper {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: transparent
  linear-gradient(0deg, #00243e 0%, #234258 41%, #546c7e00 100%) 0% 0%
  no-repeat padding-box;
  border-radius: 0px 0px 15px 15px;
  opacity: 0.8;
}
.viewLeagueProfileImageHeader {
  text-align: left;
  font: normal normal bold 20px/24px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-left: 20px;
}
.withdrawnPlayerBackground {
  opacity: 0.75;
}
.viewLeagueProfileImageText {
  text-align: left;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 20px;
  margin-left: 20px;
}
.leagueInfoImage {
  width: 400px;
  height: 400px;
}

.leagueBottomNavigation {
  margin: 10px;
}
.leaguePlayerGridList {
  max-width: 1200px;
  min-width: 300px;
  display: flex;
}
.leaguePlayerGridListItem {
  width: 300px;
  height: 300px;
}
.leaguePlayerGridListText {
  width: 300px;
}

/*
 2.4.3: Leaderboards-------------------------------------------------------------------
*/
.leaderboardCell {
  border: none;
  margin: 0;
  padding: 0;
}
.leaderboardCellInner {
  background-color: #ffffff;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100px;
  border-top: 1px solid #e9eef2;
  border-bottom: 1px solid #e9eef2;
  display: flex;
  align-items: center;
}
.leaderboardCellLeftInner {
  background-color: #ffffff;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-left: 1px solid #e9eef2;
  border-top: 1px solid #e9eef2;
  border-bottom: 1px solid #e9eef2;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100px;
  display: flex;
  align-items: center;
}
.dashbordleaderboardCellLeftInner{
  background-color: #ffffff;
  border-top: 1px solid #e9eef2;
  border-bottom: 1px solid #e9eef2;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100px;
  display: flex;
  align-items: center;
}
.radiousNone{
  border-radius: 0px !important;
}
.leaderboardCellRightInner {
  background-color: #ffffff;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-right: 1px solid #e9eef2;
  border-top: 1px solid #e9eef2;
  border-bottom: 1px solid #e9eef2;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 100px;
  display: flex;
  align-items: center;
}
.leaderboardRankWrapper {
  background: #f9fbe5 0% 0% no-repeat padding-box;
  border-radius: 15px;
  opacity: 1;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.leaderboardHighlightText {
  text-align: left;
  font: normal normal bold 15px/22px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.leaderboardStatText {
  text-align: left;
  font: normal normal normal 15px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.leaderboardStatTextBold {
  text-align: left;
  font: normal normal bold 15px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.leaderboardColumnHeaderText {
  text-align: left;
  font: normal normal normal 15px/22px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
  opacity: 1;
}
.leaderboardRatingToggleWrapper {
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #e9eef2;
  display: flex;
  align-items: center;
  border-radius: 15px;
}
.leaderboardRatingToggleTextDisabled {
  color: #00243e;
}
.leaderboardRatingToggleTextEnabled {
  color: $main-color;
}
@media (max-width: 900px) {
  .leaderboardRatingToggleWrapper {
    background-color: #ffffff;
    padding: 10px;
    border: 1px solid #e9eef2;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
  }
}
/*
 2.4.4: Activities-------------------------------------------------------------------
*/
.activityFieldSortText {
  text-align: left;
  font: normal normal medium 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
  margin-right: 10px;
}
.activitySortWrapper {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
}
.activitySortText {
  text-align: left;
  font: normal normal medium 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
}

/*
 2.4.5: Referrals-------------------------------------------------------------------
*/

.referralsHeader {
  font: normal normal bold 20px/24px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.referralsAddWrapper {
  background: #f9fbe5 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
  margin-top: 10px;
}
.referralsTextFieldHeader {
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.referralsInformation {
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.referralsCouponCodeWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
}
.referralsHistoryWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-top: 10px;
}
@media (max-width: 900px) {
  .referralsHistoryWrapper {
    width: 90%;
    margin-top: 10px;
    padding: 20px;
    border-radius: 10px;
    max-width: 75vw;
    overflow-x: scroll;
    align-self: center;
  }
}
.referralsCodeText {
  font: normal normal normal 16px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.referralsCodeCopyText {
  font: normal normal normal 16px/30px Helvetica;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
}

.referralSendButton {
  background: $main-color 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00d78b4d;
  border-radius: 10px;
  opacity: 1;
}
.referralResetButtonText {
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.referralSendButtonText {
  font: normal normal bold 16px/30px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}

/*
 2.4.6: Dashboard-------------------------------------------------------------------
*/

.dashboardSectionHeader {
  text-align: left;
  font: normal normal bold 20px/24px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
}
.textFieldHeader {
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.dashboardViewAll {
  color: #1f56fb;
  text-decoration: underline;
}
.dashboardSectionWrapper {
  border-radius: 10px;
  height: auto;
  width: auto;
  padding: 13px;
  background-color: #ffffff;
  align-items: center;
}
.OverallSummaryFirstSubSection{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.dashboardHeaderSection {
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
  cursor: pointer;
}
.OverallSummarySecondSubSection {
  height: 17px;
}
.overAllDataSection{
  margin-right: 5px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  width: 25%;
  height: 102px;
  font-weight: 600;
  font-family: sans-serif;
  border: 3px solid rgb(178, 178, 178);
}
.titleOverallSummarySubSection{
  font-weight: 600;
  color: gray;
  font-size: 21px;
}
.totalScore{
  font-weight: 600;
  font-size: 21px;
}
.winLostTitle{
  color: gray;
}
.warningMassage{
  font-weight: 400;
  font-size: 18px;
  color: red;
}
.dashbordRewardDetails{
  margin-left: 320px;
  color: #4062ff;
  text-decoration: underline;
  cursor: pointer;
}
.dashboardEmptySectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 290px;
  justify-content: center;
}
.dashboardLeaderboardWrapper {
  height: 290px;
  display: flex;
  flex-direction: column;
}
.dashboardLeagueWrapper {
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.dashboardStatsWrapper {
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.dashboardSummaryWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.dashboardStatCircle {
  width: 50px;
  height: 50px;
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.dashboardStatsCircleLight {
  background: #e1e87e 0% 0% no-repeat padding-box;
}
.dashboardStatsCircleDark {
  background: $main-color 0% 0% no-repeat padding-box;
}
.dashboardStatValue {
  text-align: center;
  font: normal normal bold 13px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.dashboardStatTitle {
  text-align: center;
  font: normal normal normal 10px/22px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
  opacity: 1;
}
.dashboardPlayerItem {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}
.dashboardPlayerItemCircle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #e1e87e 0% 0% no-repeat padding-box;
  text-align: center;
  font: normal normal bold 13px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.dashboardLeagueItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 260px;
  padding: 20px;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.dashboardMatchPointsItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: auto;
  padding: 20px 25px;
  height: 267px;
  margin-left: 10px;
  margin-right: 10px;
}

.dashboardMatchChart {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.chartMenu{
  margin-left: 0px;
}
.chartButtonText{
  margin-right: 0px;
}

.DashordChartDropDown{
  margin-left: 30px;
  position: absolute;
  margin-top: 6px;
  border: 1px solid green;
}

/*
 2.5.1: CreateLeague-------------------------------------------------------------------
*/

.createLeagueMainContent {
  display: flex;
  flex-direction: column;
  min-height: 80vh;
}
.createLeagueFormatItem {
  margin-right: 30px;
}
.createLeagueUtilityButtonText {
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.switchPopupBackButtonText {
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $pickle-main-color;
  opacity: 1;
}
.createLeagueBackButtonIcon {
  font: normal normal normal 20px/25px Material Design Icons;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.createLeagueNextButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid $main-color;
  border-radius: 10px;
  opacity: 1;
}
.createLeagueNextButtonFill {
  background: #ffffff 0% 0% no-repeat padding-box;
  background-color: $main-color;
  border: 1px solid $main-color;
  border-radius: 10px;
  opacity: 1;
}
.createLeagueNextButtonText {
  font: normal normal bold 15px/30px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.createLeagueFieldText {
  font: normal normal bold 20px/36px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.createLeagueRTEField {
  min-height: 300px;
  max-width: 70vw;
}
.createLeagueErrorText {
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #e63946;
  opacity: 1;
}
.createLeagueFieldSubtext {
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.createLeagueImageWrapper {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #c0c0c0;
  border-radius: 10px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.createLeagueCoverImage {
  height: 200px;
  width: 96.5%;
}
/*
 2.5.2: ManageLeagues-------------------------------------------------------------------
*/
.directorLeagueCoverImage {
  width: 100%;
  height: 200px;
  border-radius: 5px;
}
.directorAddTrackButton {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid $main-color;
  border-radius: 10px;
  opacity: 1;
  min-width: 100px;
  margin-top: -20px;
  margin-left: 10px;
}
.leagueCardMedia {
  height: 0;
  padding-top: 56.25%;
}

/*
 3: Components-------------------------------------------------------------------
*/
.sliderTextField {
  width: 70px;
}
/*
 3.1: List Items-------------------------------------------------------------------
*/
.listItem {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}
.listLeagueItemMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 100%;
  min-height: 200px;
  align-items: center;
}
.listLeagueItemMain:hover {
  background-color: #d3d3d3;
}
.listLeagueImage {
  z-index: 50;
  width: 124px;
  height: 124px;
  top: -40px;
}
.rewardImageDashbord {
  z-index: 50;
  width: 122px;
  height: 98px;
  top: -70px;
}
.dashbordRewardPoint{
  font-size: 26px;
  font-weight: 700;
}
.dashbordRewardPointTitle{
  color: gray;
}
.dashbordRewardLastEarnedTitle{
  color: #020202;
}
.dashBoardRedeemButton{
  margin-top: 10px;
  background: #f4b425 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  width: 60%;
  color: var(--unnamed-color-00243e);
  text-align: center;
  font: normal normal bold 16px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
  padding: 8px;
  font-size: 19px;
}

/*
 3.1.2: MyLeagueItem-------------------------------------------------------------------
*/
.myLeagueItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 260px;
  padding: 20px;
  height: 100%;
}
.myLeagueItemGridWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.listItemLeagueContentWrapper {
  margin-top: -30px;
  height: 100%;
}
.dashbordRewardContentWrapper {
  margin-top: -47px;
  height: 100%;
}
.listItemLeagueTitleText {
  text-align: center;
  font: normal normal 750 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.moderatedScheduleDilougTitleText {
  text-align: left;
  font: normal normal 750 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}

.listItemLeagueDateWrapper {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.listItemLeagueDateObj {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.listItemLeagueDateText {
  text-align: center;
  font: normal normal medium 13px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
}
.listItemLeagueDeadlineText {
  color: var(--unnamed-color-f70909);
  text-align: center;
  font: normal normal normal 11px/22px Helvetica;
  letter-spacing: 0px;
  color: #f70909;
  opacity: 1;
}
.listItemLeagueFinePrintText {
  text-align: center;
  font: normal normal normal 13px/22px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
}
.listItemLeagueJoinedText {
  text-align: center;
  font: normal normal normal 11px/22px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
  opacity: 1;
}
.listItemLeagueButtonWrapper {
  width: 100%;
  position: relative;
}
.listItemLeaguePriceBackground {
  position: absolute;
  right: 10px;
  top: -12px;
  z-index: 1;
}
.listItemLeaguePriceText {
  text-align: center;
  color: #ffffff;
  opacity: 1;
  position: absolute;
  right: 30px;
  top: 5px;
  z-index: 2;
}
.listItemLeagueJoinButton {
  background: #f4b425 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  width: 100%;
  color: var(--unnamed-color-00243e);
  text-align: center;
  font: normal normal bold 16px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
/*
 3.1.3: PlayedMatchItem-------------------------------------------------------------------
*/
.podiumIcon {
  text-align: left;
  font: normal normal normal 20px/25px Material Design Icons;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
}
.playedMatchVsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.listPlayedMatchItemMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 100%;
  min-height: 150px;
  align-items: center;
}

/*
 3.1.4: ProfileReview-------------------------------------------------------------------
*/
.profileReviewMain {
  margin: 10px;
  width: 80%;
  padding: 20px;
  border-radius: 10px;
}
/*
 3.1.5: ActivityItem-------------------------------------------------------------------
*/
.activityItemMain {
  margin: 10px;
  width: 60%;
  padding: 20px;
  border-radius: 10px;
}
@media (max-width: 900px) {
  .activityItemMain {
    margin: 10px;
    width: 90%;
    padding: 20px;
    border-radius: 10px;
  }
}
.activityDate {
  right: 10px;
  bottom: 10px;
}
.activityItemHeader {
  text-align: left;
  font: normal normal medium 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
}
.activityItemSubHeader {
  text-align: left;
  font: normal normal normal 13px/15px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
  opacity: 1;
}
.activityItemPoints {
  text-align: center;
  font: normal normal bold 13px/15px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}

.activityItemDescription {
  text-align: left;
  font: normal normal normal 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}

/*
 3.1.6: LeaguePlayerRankTableItem-------------------------------------------------------------------
*/
.leagueViewPlayerRankTableItem {
  margin: 10px;
  width: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border: "solid";
  border-color: "#E9EEF2";
  border-radius: 15px;
  background-color: #02c39a;
}
.customTableHeader
{
  color: white !important;
}
/*
 3.1.7: ListItemMatchScore-------------------------------------------------------------------
*/
.listItemMatchScoreWrapper {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #e9eef2;
  border-radius: 15px;
  opacity: 1;
  display: flex;
  flex-direction: column;
}
.listItemMatchScoreWinner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.listItemMatchScoreLoser {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.listItemMatchScoreMiddle {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 900px) {
  .listItemMatchScoreWinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .listItemMatchScoreLoser {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.listItemMatchScoreDate {
  text-align: center;
  font: normal normal normal 13px/15px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
  margin-right: 20px;
}
.listItemMatchScorePlayerName {
  margin-left: 10px;
  text-align: left;
  font: normal normal normal 15px/18px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
}
.listItemMatchScoreType {
  margin-left: 10px;
  text-align: left;
  font: normal normal bold 13px/15px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  white-space: nowrap;
}
.listItemMatchScoreText {
  text-align: center;
  font: normal normal bold 13px/15px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.listItemMatchScoreMiddleText {
  text-align: center;
  font: normal normal normal 13px/15px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
}
.listItemMatchScoreLocation {
  margin-top: 20px;
  text-align: left;
  font: normal normal bold 13px/15px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  white-space: nowrap;
}
/*
 3.1.8: DoublesPartnerListItem-------------------------------------------------------------------
*/

.doublesPartnerListItemHeader {
  font: normal normal bold 15px/22px Helvetica;
  color: $main-color;
}
.doublesPartnerListItemWrapper {
  border-color: #ffffff;
  border-width: 1px;
  border-radius: 10px;
  border-style: solid;
  cursor: pointer;
}
.doublesPartnerListItemWrapper:hover {
  border-color: $main-color;
}

/*
 3.2.2: ScoreUpload-------------------------------------------------------------------
*/
.scoreUploadFieldWrapper {
  height: 50vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  box-sizing: border-box;
  padding: 0 10px;
}
.scoreUploadFieldWrapper::-webkit-scrollbar-thumb {
  background-color: #c3c0c0;
}
.scoreUploadFieldText {
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.scoreUploadFieldTextPlaceholder {
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.scoreUploadRadioText {
  font: normal normal normal 16px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.radioSelect {
  margin: 10px;
}
.radioElement {
  margin-left: 10px;
}

.scoreWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.scoreItem {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.scoreItemInput {
  width: 20%;
}
.submitScoreButton {
  border-color: #02c39a;
  width: 100%;
  align-self: center;
}

/*
 3.2.3: SignUp-------------------------------------------------------------------
*/

/*
 3.2.5: RewardEdit-------------------------------------------------------------------
*/
.rewardEditImageWrapper {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rewardEditImage {
  width: 100%;
  height: auto;
}

/*
 3.2.6: RewardPurchase-------------------------------------------------------------------
*/
.rewardPreviewImageWrapper {
  margin: 10px;
  padding: 10px;
  border-style: solid;
  border-radius: 5px;
  border-width: 1px;
  border-color: #02c39a;
}
.rewardPurchaseItemName {
  font: normal normal bold 22px/28px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.rewardPurchaseItemPrice {
  font: normal normal bold 15px/22px Helvetica;
  letter-spacing: 0px;
  color: #f4b425;
  opacity: 1;
}
.rewardPurchaseItemDescription {
  font: normal normal normal 16px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.rewardPurchaseItemSubtitle {
  font: normal normal medium 16px/19px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
  opacity: 1;
}
.rewardPurchaseFieldText {
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
}
.rewardPurchaseButton {
  background: transparent linear-gradient(180deg, $main-color 0%, $base-color 100%) 0%
  0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}
.rewardPurchaseButtonText {
  text-align: center;
  font: normal normal bold 22px/26px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.rewardPurchaseBackButtonText {
  text-align: right;
  font: normal normal normal 17px/28px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
/*
 3.2.7: LeaguePurchase-------------------------------------------------------------------
*/

.leaguePurchaseWrapper {
  display: flex;
  width: 100%;
}
.leaguePurchaseSideBar {
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.leaguePurchaseStripeIcon {
  width: 60px;
}
.leaguePurchaseAcceptedPaymentsImg {
  width: 200px;
}
.leaguePurchaseCardInfo {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
}
.leaguePurchaseLeagueName {
  text-align: left;
  font: normal normal bold 22px/26px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-top: 25px;
}
.leaguePurchaseDateText {
  text-align: left;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}
.leaguePurchaseErrorText {
  text-align: right;
  font: normal normal normal 15px/22px Helvetica;
  letter-spacing: 0px;
  color: #f70909;
  opacity: 1;
}
.leaguePurchaseDivider {
  background-color: #ffffff;
}
.leaguePurchaseDetailText {
  text-align: left;
  font: normal normal normal 16px/22px Helvetica;
  letter-spacing: 0px;
  color: #fafafa;
  opacity: 1;
}
.leaguePurchasePriceText {
  text-align: left;
  font: normal normal bold 22px/26px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-right: 5px;
}
.leaguePurchaseCardInfoTitle {
  text-align: left;
  font: normal normal bold 20px/24px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
  margin-bottom: 20px;
}
.leaguePurchaseTextFieldLabel {
  text-align: left;
  font: normal normal normal 14px/30px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.leaguePurchaseCouponButton {
  text-align: left;
  font: normal normal bold 16px/30px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.leaguePurchaseFormWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.leaguePurchaseFormButton {
  background: transparent linear-gradient(180deg, $main-color 0%, $base-color 100%) 0%
  0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
}
.leaguePurchaseFormButtonText {
  text-align: center;
  font: normal normal bold 22px/26px Helvetica;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.leaguePurchaseSpaceHolder {
  min-height: 20px;
}
.leaguePurchaseSuccessIcon {
  text-align: left;
  font: normal normal normal 72px/86px Material Design Icons;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
}
.leaguePurchaseSuccessText {
  text-align: center;
  font: normal normal normal 20px/24px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.leaguePurchaseCancelButtonText {
  text-align: right;
  font: normal normal normal 17px/28px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}

/*
 3.3: SideMenu-------------------------------------------------------------------
*/

.sideBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: "white";
}
.sideDrawer {
  z-index: 2;
  flex-shrink: 0;
  width: 250px;
}
.sideBarSpacing {
  height: 75px;
}

/*
 3.4: Playoffs-------------------------------------------------------------------
*/

.enterResultPlayoffBtn{
  border-radius: 10px;
  opacity: 1;
  width: 40%;
  background: rgb(235 235 240);
  padding: 0px;
  font-weight: 600;
}
.playoffPage {
  display: flex;
  width: 100%;
  min-height: 500px;
}
.playoffPlayerList {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  height: "100%";
}
.playoffBracketWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 5;
}
.playoffObject {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  min-width: 200px;
  margin: 10px;
}
.moderatePlayoffObject{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  min-width: 129px;
  max-width: 113px;
  max-height: 76px;
  margin: 6px;
  font-size: 10px;
  overflow: overlay;
}

.moderatePlayerObjText{
  font-size: 11px;
  font-family: Helvetica;
  font-weight: 600;
  line-height: 1.5;
}

.playoffObjectTextWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-left: 10px;
}
.playoffsObjectPlayerText {
  text-align: left;
  font: normal normal bold 13px/15px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.playoffsFinalsCrown {
  color: #c8d952;
  margin-top: -35px;
  margin-left: 4px;
  opacity: 1;
}
.playoffsFinalsCrownDoubles {
  color: #c8d952;
  margin-top: -35px;
  margin-right: 4px;
  opacity: 1;
}
.playoffsObjectScoreText {
  text-align: left;
  font: normal normal normal 20px/23px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}
.playoffScoreWrapper {
  background: #f9fbe5 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.playoffSlot {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
  min-width: 200px;
  min-height: 100px;
  margin: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e87e;
  border-radius: 25px;
  opacity: 1;
  align-items: center;
}
.ModeratePlayOffSlot{
  display: grid;
  justify-content: space-evenly;
  padding: 10px;
  min-width: 200px;
  min-height: 100px;
  margin: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e87e;
  border-radius: 25px;
  opacity: 1;
  grid-template-columns: 1fr 1fr;
}
.playoffStage {
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-evenly;
}

/*
 3.5: SearchBar-------------------------------------------------------------------
*/
.searchBar3Fields {
  width: 30%;
}
.searchBar4Fields {
  width: 22.5%;
}
.searchBar5Fields {
  width: 18%;
}
.searchBar6Fields {
  width: 15%;
}
.searchBar7Fields {
  width: 12.8%;
}
.searchBarClearField {
  width: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.leagueSearchWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.leagueSearchBarButtonIcon {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-55b56a);
  text-align: left;
  font: normal normal normal 24px/29px Material Design Icons;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
  margin-left: 10px;
}
.leagueSearchBarFilterPopoverWrapper {
  display: flex;
  align-items: center;
  width: 60vw;
  height: 35px;
  padding: 10px;
  border: 1px solid var(--unnamed-color-ebebeb);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  border-radius: 24px;
  opacity: 1;
}
.leagueSearchBarClearText {
  text-align: center;
  font: normal normal normal 15px/18px Helvetica;
  letter-spacing: 0px;
  color: $base-color;
  opacity: 1;
}
.leagueSearchBarField {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  border: 1px solid var(--unnamed-color-ebebeb);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  opacity: 1;
  width: 50%;
}
@media (max-width: 900px) {
  .leagueSearchBarFilterPopoverWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    height: 30vh;
    padding: 10px;
    border: 1px solid var(--unnamed-color-ebebeb);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    border-radius: 24px;
    opacity: 1;
  }
  .leagueSearchBarField {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    border: 1px solid var(--unnamed-color-ebebeb);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #ebebeb;
    opacity: 1;
    width: 100%;
  }
  .searchBar3Fields {
    width: 90%;
    padding-bottom: 10px;
    margin-left: -15px;
  }
  .searchBar4Fields {
    width: 90%;
    padding-bottom: 10px;
    margin-left: -15px;
  }
  .searchBar5Fields {
    width: 90%;
    padding-bottom: 10px;
    margin-left: -15px;
  }
  .searchBar6Fields {
    width: 90%;
    padding-bottom: 10px;
    margin-left: -15px;
  }
  .searchBar7Fields {
    width: 90%;
    padding-bottom: 10px;
    margin-left: -15px;
  }
  .searchBarClearField {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.leagueSearchBarFilterSection {
  width: 15%;
}
.leagueSearchBarFieldIcon {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-55b56a);
  text-align: left;
  font: normal normal normal 24px/29px Material Design Icons;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}
.leagueSearchBarSliderRange {
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-8a8e91);
  text-align: right;
  font: normal normal normal 15px/18px Helvetica;
  letter-spacing: 0px;
  color: #8a8e91;
  opacity: 1;
  min-width: 40px;
}
.leagueSearchBarFilterSlider {
  width: 50%;
  margin-left: 8px;
  margin-right: 15px;
}
.leagueSearchBarSliderWrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
}

/*
 3.6: DropdownCheckbox-------------------------------------------------------------------
*/
.dropdownCheckboxButtonText {
  margin-right: 15px;
  text-align: left;
  font: normal normal normal 15px/18px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
}

/*
 3.7: CustomStepper-------------------------------------------------------------------
*/

.stepperMain {
  background-color: $light-color;
}
.stepperLabel {
  text-align: left;
  font: normal normal medium 16px/19px Helvetica;
  letter-spacing: 0px;
  color: $main-color;
  opacity: 1;
}

/*
 3.8: CustomTextField-------------------------------------------------------------------
/*
/*
3.9 : EventModerationScreen-----------------------------------------------------------------------  */

.eventCourtSection{
  width: 100%;
  border-radius: 25px;
}
.eventCourtSection1{
  width: 100%;
  height: 100%;
  border-radius: 25px;
}
.modearateStartButton{
  background: #f4b425 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  width: auto;
  color: var(--unnamed-color-00243e);
  text-align: center;
  font: normal normal bold 16px/22px Helvetica;
  letter-spacing: 0px;
  color: #00243e;
  opacity: 1;
  margin-bottom: 14px;
}
.modearateCompleteAndButton {
  width: auto;
  color: var(--unnamed-color-00243e);
  text-align: center;
  font: normal normal bold 16px/22px Helvetica;
  letter-spacing: 0px;
  color: white;
  opacity: 1;
  background: transparent linear-gradient(180deg, $main-color 0%, $base-color 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 14px;
}
.refreshiconModerate{
  margin-bottom: -6px;
  margin-left: 6px;
}
.moderateCloseAddBtn{
  display: flex;
  justify-content: space-between;
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: rgb(230, 230, 230);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(170, 170, 170);
}

.QRCode_block {
  position: relative;
  transition: all 0.8s;
  margin-top: 10px;
  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000042;
    opacity: 0;
    border-radius: 5px;
  }
  .QRCode_download_btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 18px;
    height: 30px;
    font-weight: 600;
    z-index: 1;
    width: 100%;
  }
  &:hover {
    transition: all 0.8s;
    &::after{
      opacity: 1;
    }
    .QRCode_download_btn {
      opacity: 1;
    }
  }
}
