$base-color: #028360;
$main-color: #55b56a;
$light-color:#edf8f4;
$pickle-base-color: #340761;
$pickle-main-color: #6115AD;
$pickle-light-color: #F9EBFF;
html {
  height: 100%;
  min-height: 100vh;
}

body {
  padding: 0px;
  margin: 0px;
  height: 100%;
  overflow-y: auto;
  overflow-x: auto;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "SF Pro Display" sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@import "./project";
@import "./pickABallall";
