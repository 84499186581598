$base-color: #340761;
$main-color: #6115AD;
$light-color:#F9EBFF;
$gradient-light:#c444eb;
$gradient-dark:#51089a;
$tennis-base-color: #028360;
$tennis-main-color: #55b56a;
$tennis-light-color:#edf8f4;
.pick-a-ball{

  .cancelButtonText {
    color: $main-color;
  }

  .purchaseButton {
    background: transparent linear-gradient(180deg, $gradient-light 0%, $gradient-dark 100%) 0%
    0% no-repeat padding-box;
  }

  .highlightText {
    color: $main-color;
  }
  .highlightHeader {
    color: $main-color;
  }

  .pageLeft {
    background: $light-color 0% 0% no-repeat padding-box;
  }
  .landingPageSignupTextSmall {
    color: $main-color;
  }
  .landingPageLoginText {
    color: $base-color;
  }
    .switchPopupLoginText {
      color: $tennis-base-color;
    }
  .landingPagePrimarySmallText {
    color: $base-color;
  }
     .switchPopupPrimarySmallText {
       color: $tennis-base-color;
     }
  .landingPageSecondarySmallText {
    color: $main-color;
  }
  .switchableColorLight{
    background:$tennis-light-color 0% 0% no-repeat padding-box;
  }

  .loginButton {
    background: $main-color 0% 0% no-repeat padding-box;
  }
    .switchPopUpButton {
      background: $tennis-main-color 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px $tennis-main-color;
    }

  .signUpText {
    color: $main-color;
  }
  .signUpRatingText {
    color: $main-color;
  }

  .signUpNextButton {
    border: 1px solid $main-color;
  }
  .signUpNextButtonText {
    color: $main-color;
  }
  .signUpIcon {
    color: $main-color;
  }

  .dashboardSideNavButtonText {
    color: $main-color;
  }
  @media (max-width: 900px) {
    .dashboardSideNavButtonText {
      color: $main-color;
    }
  }
  .appBarGSMText {
    color: $main-color;
  }
  .appBarLocationText {
    color: $main-color;
  }
  .appBarLocationIcon {
    color: $main-color;
  }
  .appBarProfileButtonText {
    color: $base-color;
  }
  .appBarProfileMenuText {
    color: $base-color;
  }
  .appBarUploadScoreButtonText {
    color: $base-color;
  }
  .appBarUploadScoreButtonIcon {
    color: $base-color;
  }

  .profileNavButtonTextUnselected {
    color: $main-color;
  }

  .profileHighlightFieldText {
    color: $main-color;
  }
  .profileSaveButton {
    border: 1px solid $main-color;
  }

  .profileSaveButtonText {
    color: $main-color;
  }
.stepperMain {
  background-color: rgb(252 244 255);
}

  .rewardsPointsTotalWrapper {
    background: transparent linear-gradient(180deg,
      $gradient-light 0%,
      $gradient-dark 100%) 0%
    0% no-repeat padding-box;
  }

    .SwitchPopupPageLeft {
      background: $tennis-light-color 0% 0% no-repeat padding-box;
  border-right: 2px solid $tennis-base-color;
    }
  .rewardsHighlightText {
    color: $main-color;
  }
  .rewardsMethodIcon {
    color: $main-color;
  }

  .circularProgressPercent {
    color: $main-color;
  }

  .profileStatisticsHeaderValueText {
    color: $base-color;
  }

  .leaguesPageLoadMoreText {
    color: $main-color;
  }

  .viewLeagueCategoryText {
    color: $main-color;
  }

  .viewLeagueDirectorContactIcon {
    color: $base-color;
  }

  .viewLeagueRewardValueText {
    color: $base-color;
  }

  .leaderboardHighlightText {
    color: $main-color;
  }

  .leaderboardRatingToggleTextEnabled {
    color: $main-color;
  }

  .activitySortText {
    color: $base-color;
  }


  .referralsCodeCopyText {
    color: $base-color;
  }

  .referralSendButton {
    background: $main-color 0% 0% no-repeat padding-box;
  }
  .referralResetButtonText {
    color: $main-color;
  }

  .dashboardStatsCircleDark {
    background: $main-color 0% 0% no-repeat padding-box;
  }

  .createLeagueUtilityButtonText {
    color: $main-color;
  }
  .switchPopupBackButtonText{
    color: $tennis-main-color;
  }
  .createLeagueBackButtonIcon {
    color: $main-color;
  }
  .createLeagueNextButton {
    border: 1px solid $main-color;
  }
  .createLeagueNextButtonFill {
    background-color: $main-color;
    border: 1px solid $main-color;
  }
  .createLeagueNextButtonText {
    color: $main-color;
  }

  .directorAddTrackButton {
    border: 1px solid $main-color;
  }

  .podiumIcon {
    color: $base-color;
  }

  .activityItemHeader {
    color: $base-color;
  }

  .activityItemPoints {
    color: $main-color;
  }

  .listItemMatchScorePlayerName {
    color: $main-color;
  }
  .listItemMatchScoreText {
    color: $main-color;
  }

  .doublesPartnerListItemHeader {
    color: $main-color;
  }

  .doublesPartnerListItemWrapper:hover {
    border-color: $main-color;
  }

  .rewardPurchaseButton {
    background: transparent linear-gradient(180deg, $gradient-light 0%, $gradient-dark 100%) 0%
    0% no-repeat padding-box;
  }

  .rewardPurchaseBackButtonText {
    color: $main-color;
  }

  .leaguePurchaseCouponButton {
    color: $main-color;
  }
  .leaguePurchaseFormButton {
    background: transparent linear-gradient(180deg, $gradient-light 0%, $gradient-dark 100%) 0%
    0% no-repeat padding-box;
  }

  .leaguePurchaseSuccessIcon {
    color: $base-color;
  }

  .leaguePurchaseCancelButtonText {
    color: $main-color;
  }

  .leagueSearchBarButtonIcon {
    color: $main-color;
  }

  .leagueSearchBarClearText {
    color: $base-color;
  }

  .leagueSearchBarFieldIcon {
    color: $main-color;
  }

  .stepperLabel {
    color: $main-color;
  }
  .appBarProfileMenuIcon{
    color: $main-color;
  }
    .DashordChartDropDown{
      border: 1px solid $main-color;;
    }

.selectedbutton {
  background: #340761;
}

.leaderboardRankWrapper{
  background-color: $light-color
}

  .modearateCompleteAndButton {
    background: transparent linear-gradient(180deg, $gradient-light 0%, $gradient-dark 100%) 0% 0% no-repeat padding-box;
  }

}